.gamer-icon {
  color: #6ac66b !important;
  margin: 18px !important;
  height: 60px !important;
  width: 60px !important;
}

.gamer-card-content {
  display: flex;
}

.gamer-card-parent {
  color: #9e9e9e !important;
}

.add-lesson-icon {
  color: #9e9e9e !important;
  margin: 2px !important;
  height: 100px !important;
  width: 100px !important;
  align-content: center;
}

.add-participant-icon {
  color: #9e9e9e !important;
  margin: 2px !important;
  height: 100px !important;
  width: 100px !important;
  align-content: center;
}

.create-modal-muni2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 80%;
  height: 70vh;

  padding-left: 2em;
  padding-top: 2em;
  max-height: 600px;
  max-width: 600px;
}

.create-form-hour-muni2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.create-modal-lessonEntries {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 80%;
  height: 80%;

  padding-left: 2em;
  padding-top: 6em;
  padding-bottom: 6em;
}
.create-form-hour-lessonEntries {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.lessonEditButton {
  margin-left: 68px !important;
  min-width: 30px !important;
  width: 45px !important;
}

.lessonRemoveButton {
  min-width: 30px !important;
  width: 45px !important;
}
