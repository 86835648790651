.MuiSvgIcon-colorWhite {
  color: #ffffff !important;
}

#bottom-nav {
  display: none;
  margin-top: 0.5rem;
  padding: 0.1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
}

.bottom-nav-colors {
  background-color: #8f00e2 !important;
  color: #fff !important;
}

.hamburger-nav-left:hover {
  cursor: pointer;
}
.hamburger-nav-left {
  display: none;
  position: relative;
  top: 1rem;
  left: 1rem;
}

#left-burger {
  position: relative;
  left: 180px;
  display: none;
}
.open-burger {
  width: 240px !important;
  border: #8f00e2;
}

.MuiDrawer-paper {
  background-color: #8f00e2 !important;
  overflow-x: hidden !important;
  color: white !important;
}

.css-cveggr-MuiListItemIcon-root {
  color: white !important;
}

language-selector {
  text-align: center;
  color: white !important;
}

@media screen and (max-width: 1100px) {
  .hamburger-nav-left {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  #bottom-nav {
    display: block;
  }
}
