.user-icon {
  color: #6ac66b !important;
  margin: 18px !important;
  height: 60px !important;
  width: 60px !important;
}

.action-icon {
  color: #3a71de !important;
  margin: 18px !important;
  height: 35px !important;
  width: 35px !important;
}

.action-icon-no-margin {
  color: #3a71de !important;
  margin: 0px !important;
  height: 35px !important;
  width: 35px !important;
}
