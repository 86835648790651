.arena-logo {    
    text-align: center;
    margin-bottom: 0px;
}

.arena-logo-text {    
    font-family: "adumuregular";
    text-transform: uppercase;
    font-size: 24px;
    color: #121212;
    text-align: center;
    margin-top: -18px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .arena-logo-text {
        font-size: 18px;        
    }
}