#muni-register {
  width: 800px;
  font-size: medium;
}

@media screen and (max-width: 600px) {
  #muni-register {
    width: 550px;
    font-size: medium;
  }
}

@media screen and (max-width: 450px) {
  #muni-register {
    width: 440px;
    font-size: small;
  }
}
