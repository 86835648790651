* {
  /*border: 3px solid rgb(189, 31, 189);*/
}

.slot-page {
  width: 70%;
  margin: 0 auto;
  padding: 2rem;
  background-color: whitesmoke;
  border-radius: 25px;
}
@media (max-width: 550px) {
  .slot-page {
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0px;
    height: fit-content;
    min-height: 110vh;
  }
  .slot-form {
    height: calc(65vh) !important;
  }
}
ul {
  list-style-type: disc;
}
.slot-info {
  text-align: center;

  padding: 0.4rem;
  margin: 0.5rem;
  background-color: white;
}
.plans-list {
  width: 70px;
  margin: 0 auto;
}

.gamer {
  min-width: 300px;
  width: 10%;
  text-align: justify;
  padding: 1em;
  margin: 0 auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.date-gamer-slot {
  position: relative;
  top: -3px;
}
.consent-age-slot {
  color: #2d2c2c;
  position: relative;
  top: 1px;
}
.icons-new-gamer {
  float: right;
}
.icons-new-gamer > *:hover {
  font-size: x-large;
}
.disable-card {
  background-color: rgb(175, 172, 172) !important;
}

body {
  background-color: #fff;
}
.basic-multi-select {
  width: 350px;
}

.gedufilter-multi-select {
  margin-bottom: 1em;
}

.gedusearch-single-select {
  width: 13.5em;
  margin-bottom: 1em;
}

.div-slot-page {
  overflow: hidden;
}
.label-slot {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120%;
  display: block;
  overflow: hidden;
}

.label-slot:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.select-gamer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 32px;
  list-style: none;
}
.card-gamer:hover:not(.disable-card-hover) {
  cursor: pointer;
  box-shadow: 5px 5px #888888;
}
.selected-gamer {
  -webkit-box-shadow: 0 0 5px 2px rgb(189, 31, 189) !important;
  -moz-box-shadow: 0 0 5px 2px rgb(189, 31, 189) !important;
  box-shadow: 0 0 5px 2px rgb(189, 31, 189) !important;
}
.gamer > p > span {
  position: relative;
  top: -5px;
}

.slot {
  background-color: #faa701a4;
  padding: 0.5rem;
  border-radius: 25px;
  text-align: center;
}
.slot:hover {
  cursor: pointer;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.2);
}

.selected {
  border: 3px solid rgb(189, 31, 189);
}

.seatCount-slot {
  color: rgb(189, 31, 189);
}

.logo-slot {
  width: 10rem;
}

.slot-resume {
  border-style: solid;
  border-color: #c801fa;
  background-color: #fbb104;
  border-radius: 13px;
  min-width: 300px;
  width: 10%;
  text-align: center;
  padding: 0.5em;
  margin: 0 auto;
  margin-top: 0.8em;
  margin-bottom: 0.5em;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.2);
}

.slot-form {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  min-width: 375px;
  width: 20%;
  text-align: justify;
  height: 50vh;
}

.checkbox-slot + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  font-family: "Arial";
}

.checkbox-slot {
  display: none;
}

.checkbox-slot + label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

.checkbox-slot + label:not(.disable-check):active:before {
  transform: scale(0);
}

.checkbox-slot:checked + label:before {
  background-color: #c801fa;
  border-color: #ed820a;
  color: #fff;
}
.disable-check:before {
  background-color: rgb(174, 173, 173);
}
.disable-check:hover {
  cursor: default;
}

.slot-txt {
  color: #a303cb;
}

.btn-slot {
  background-color: #a303cb !important;
  color: white !important;
}
.btn-slot:hover:not(.disable-next) {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.999) !important;
  cursor: pointer;
}
.btn-txt-slot {
  position: relative;
  top: 2px;
}

.disable-next {
  background-color: #888888 !important;
}
.disable-next:hover {
  cursor: default;
}
