/* activityGenerator.css */
.background {
    background-image: url(/src/IMG/yellow_brickwall.png);
    background-repeat: repeat;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;
}

/* activityGenerator.css */
.carousel-frame {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    width: 800px;
    height: 600px;
    background-color: black;
    background-image: url(/src/IMG/frame.jpg);
    background-size: 800px 600px;
    background-position: center;
}

.activity {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 500px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-generator-image {
    width: 40%;
}