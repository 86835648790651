@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


@font-face {
    font-family: 'adumuregular';
    src: url('./Static/fonts/adumu-webfont.woff2') format('woff2'), url('./Static/fonts/adumu-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sansregular';
    src: url('./Static/fonts/worksans-variablefont_wght-webfont.woff2') format('woff2'), url('./Static/fonts/worksans-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}